import "./styles.scss";
import Alpine from "alpinejs";
import { resetField } from "./uitl/reset-field";
import "./components/error";
import "./components/marketing/header";
import "./components/marketing/tabpanel";
import "./templwind/components/alert";

if (typeof window !== "undefined") {
  window.Alpine = Alpine;
  Alpine.start();
}

window.resetField = resetField;

export default Alpine;
