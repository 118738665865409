class WebTabPanel extends HTMLElement {
  private tabs: NodeListOf<HTMLElement> | null = null;
  private panels: NodeListOf<HTMLElement> | null = null;

  constructor() {
    super(); // Always call super() first in the constructor.
  }

  connectedCallback(): void {
    console.log("WebTabPanel connected");
    // Initialize tabs and panels
    this.tabs = this.querySelectorAll('[role="tab"]');
    this.panels = this.querySelectorAll('[role="tabpanel"]');

    // Add event listeners to each tab
    this.tabs.forEach((tab) => {
      tab.addEventListener("click", (e) => this.onTabClick(e));
    });
  }

  disconnectedCallback(): void {
    // Clean up event listeners when the element is removed from the DOM
    this.tabs?.forEach((tab) => {
      tab.removeEventListener("click", (e) => this.onTabClick(e));
    });
  }

  // Handle tab click events
  private onTabClick(event: Event): void {
    const selectedTab = event.target as HTMLElement;
    const button = selectedTab.closest("[aria-controls]");
    const panelId = button?.getAttribute("aria-controls");

    // Activate the clicked tab and deactivate all others
    this.tabs?.forEach((tab) => {
      const isActive = tab === button;
      tab.setAttribute("aria-selected", String(isActive));
      tab.setAttribute("tabindex", isActive ? "0" : "-1");

      const ancestorGroupDiv = tab.closest(".group");

      // Manage Tailwind classes for active and inactive states
      if (isActive) {
        tab.classList.remove("text-blue-100", "hover:text-white");
        tab.classList.add("text-blue-600");

        // Add selected styles
        ancestorGroupDiv?.classList.add(
          "bg-white",
          "lg:bg-white/10",
          "lg:ring-1",
          "lg:ring-inset",
          "lg:ring-white/10"
        );
        ancestorGroupDiv?.classList.remove(
          "hover:bg-white/10",
          "lg:hover:bg-white/5"
        );
      } else {
        tab.classList.add("text-blue-100", "hover:text-white");
        tab.classList.remove("text-blue-600");

        // Add unselected styles
        ancestorGroupDiv?.classList.add(
          "hover:bg-white/10",
          "lg:hover:bg-white/5"
        );
        ancestorGroupDiv?.classList.remove(
          "bg-white",
          "lg:bg-white/10",
          "lg:ring-1",
          "lg:ring-inset",
          "lg:ring-white/10"
        );
      }
    });

    // Show the corresponding panel and hide others
    this.panels?.forEach((panel) => {
      const isActive = panel.id === panelId;
      panel.hidden = !isActive;

      // Toggle visibility classes using Tailwind
      if (isActive) {
        panel.classList.remove("hidden");
      } else {
        panel.classList.add("hidden");
      }
    });
  }
}

customElements.define("web-tabpanel", WebTabPanel);
