class WebHeader extends HTMLElement {
  private mobileMenuButton: HTMLElement | null = null;
  private mobileMenu: HTMLElement | null = null;

  constructor() {
    super(); // Always call super() first in the constructor.
  }

  connectedCallback(): void {
    console.log("WebHeader connected");

    // Get the mobile menu and the toggle button from the shadow DOM or light DOM
    this.mobileMenuButton = this.querySelector("#mobile-menu-button");
    this.mobileMenu = this.querySelector("#mobile-menu");

    // Event listener to toggle the mobile menu
    this.mobileMenuButton?.addEventListener(
      "click",
      this.toggleMenu.bind(this)
    );
  }

  disconnectedCallback(): void {
    // Cleanup event listeners when the element is removed from the DOM
    this.mobileMenuButton?.removeEventListener(
      "click",
      this.toggleMenu.bind(this)
    );
  }

  // Toggle the mobile menu's visibility
  private toggleMenu(): void {
    const isExpanded =
      this.mobileMenuButton?.getAttribute("aria-expanded") === "true";
    const newExpandedState = !isExpanded;
    this.mobileMenuButton?.setAttribute(
      "aria-expanded",
      String(newExpandedState)
    );

    if (this.mobileMenu) {
      if (newExpandedState) {
        this.mobileMenu.classList.remove("hidden");
      } else {
        this.mobileMenu.classList.add("hidden");
      }
    }
  }
}

customElements.define("web-header", WebHeader);
